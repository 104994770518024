<template>
  <div>
    <vertical-tabs-components
      v-if="tabsPagamento && tabsPagamento.length"
      :tabs="tabsPagamento"
      :isManyContents="false"
      @set-tab="setTab"
    >
      <pagamento-form :tagClass="tabPagamentoParameter.tab"></pagamento-form>
    </vertical-tabs-components>
  </div>
</template>

<script>
import VerticalTabsComponents from "@/components/vertical-tabs/VerticalTabsComponents";

import { mapGetters, mapActions, mapMutations } from "vuex";
import PagamentoForm from "./components/pagamento/PagamentoForm.vue";

export default {
  components: {
    VerticalTabsComponents,
    PagamentoForm
  },
  computed: {
    ...mapGetters("parametrizacao-module", [
      "tabsPagamento",
      "tabPagamentoParameter",
      "modalitiesGroups"
    ])
  },
  methods: {
    ...mapActions("parametrizacao-module", [
      "getInstallmentParameter",
      "getModalitiesGroups",
      "getModalitiesByGroup"
    ]),
    ...mapMutations("parametrizacao-module", ["updateTabPagamentoParameter"]),

    loadGetInstallmentParameter() {
      this.$onpoint
        .loading(() => {
          return this.getInstallmentParameter().catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
        })
        .finally(() => {
          this.$forceUpdate();
          this.loadGetModalitiesGroups();
        });
    },

    async loadGetModalitiesGroups() {
      return this.getModalitiesGroups()
        .catch(ex => {
          this.$onpoint.errorModal(ex.response.data.Errors);
        })
        .then(() => {
          this.$forceUpdate();
          this.loadGetModalities();
        });
    },

    async loadGetModalities() {
      this.modalitiesGroups.forEach(group => {
        this.getModalitiesByGroup(group.ModalityGroupUniqueId)
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          })
          .finally(() => {
            this.$forceUpdate();
          });
      });
    },

    setTab(event) {
      this.updateTabPagamentoParameter(
        event.content.data || { Class: event.content.tab }
      );
    }
  },
  async beforeMount() {
    this.loadGetInstallmentParameter();
  },
  created() {
    this.$appInsights.trackPageView({
      name: "param-pagamento",
      url: window.location.href
    });
  }
};
</script>

<style lang="scss" scoped></style>
