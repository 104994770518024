var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tabsPagamento && _vm.tabsPagamento.length
        ? _c(
            "vertical-tabs-components",
            {
              attrs: { tabs: _vm.tabsPagamento, isManyContents: false },
              on: { "set-tab": _vm.setTab }
            },
            [
              _c("pagamento-form", {
                attrs: { tagClass: _vm.tabPagamentoParameter.tab }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }