<template>
  <div id="pagamento-form">
    <ValidationObserver v-slot="{ invalid }">
      <vs-row vs-type="flex">
        <ValidationProvider
          name="nome do modelo de parâmetro"
          rules="required"
          v-slot="{ errors }"
          class="vx-col w-full"
        >
          <vs-input
            label="Nome do modelo de parâmetro"
            class="w-full"
            v-model="pagamento.Name"
            size="large"
            :danger-text="errors[0]"
            :danger="!!errors[0]"
          />
        </ValidationProvider>

        <ValidationProvider
          name="número maximo de parcelas"
          rules="required"
          v-slot="{ errors }"
          class="vx-col lg:w-1/4 md:w-1/2 w-full"
        >
          <vs-select
            class="large w-full"
            label="Número máximo de parcelas"
            v-model="pagamento.MaxNumberInstallment"
            :danger="!!errors[0]"
            :danger-text="errors[0]"
          >
            <vs-select-item
              :value="item"
              :text="item"
              :key="item"
              v-for="item in 7"
            />
          </vs-select>
        </ValidationProvider>

        <ValidationProvider
          name="valor mínimo de parcela"
          rules="required"
          v-slot="{ errors }"
          class="vx-col lg:w-1/4 md:w-1/2 w-full container-element"
        >
          <label class="label-content label">Valor mínimo de parcela</label>
          <currency-input
            v-model="pagamento.MinValueInstallment"
            class="w-full vs-inputx vs-input--input large hasValue"
          />
          <span class="text-danger text-xs" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>

        <ValidationProvider
          name="vencimento mín da 1ª parcela (dias)"
          rules="required"
          v-slot="{ errors }"
          class="vx-col lg:w-1/4 md:w-1/2 w-full"
        >
          <vs-input
            label="Vencimento mín da 1ª parcela (dias)"
            class="w-full"
            v-model.number="pagamento.MinDaysFirstInstallment"
            size="large"
            :danger-text="errors[0]"
            :danger="!!errors[0]"
            v-mask="'#####'"
          />
        </ValidationProvider>

        <ValidationProvider
          name="vencimento máx da 1ª parcela (dias)"
          rules="required"
          v-slot="{ errors }"
          class="vx-col lg:w-1/4 md:w-1/2 w-full"
        >
          <vs-input
            label="Vencimento máx da 1ª parcela (dias)"
            class="w-full"
            v-model.number="pagamento.MaxDaysFirstInstallment"
            size="large"
            :danger-text="errors[0]"
            :danger="!!errors[0]"
            v-mask="'#####'"
          />
        </ValidationProvider>

        <div class="vx-col lg:w-1/3 md:w-1/2 w-full">
          <vs-dropdown vs-custom-content vs-trigger-click class="w-full">
            <ValidationProvider
              name="periodicidade de pagamento entre as parcelas"
              rules="required"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full "
                v-model="pagamento.PaymentFrequency"
                label="Periodicidade de pagamento entre as parcelas"
                size="large"
                :danger-text="errors[0]"
                :danger="!!errors[0]"
              />
            </ValidationProvider>

            <vs-dropdown-menu class="loginx">
              <vs-dropdown-item
                :class="{ active: item.value == pagamento.PaymentFrequency }"
                :key="item.value"
                v-for="item of paymentFrequency"
                @click="pagamento.PaymentFrequency = item.value"
              >
                {{ item.text }}
              </vs-dropdown-item>

              <vs-input
                label="Outro:"
                class="w-full pt-5"
                v-model="pagamento.PaymentFrequency"
                placeholder="Informe o intervalo desejado"
                size="large"
                v-mask="'#####'"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <ValidationProvider
          name="venc da última parcela antes do final de vigência (dias)"
          rules="required"
          v-slot="{ errors }"
          class="vx-col lg:w-1/3 md:w-1/2 w-full"
        >
          <vs-select
            class="large w-full"
            label="Venc da última parcela antes do final de vigência (dias)"
            v-model="pagamento.DueDateLastInstallment"
            :danger-text="errors[0]"
            :danger="!!errors[0]"
          >
            <vs-select-item
              :value="item"
              :text="item"
              :key="item"
              v-for="item in 30"
            />
          </vs-select>
        </ValidationProvider>

        <div class="vx-col lg:w-1/3 md:w-1/2 w-full">
          <div class="switch-container">
            <label>Cobrar juros nas parcelas?</label>
            <vs-switch
              class="ml-auto"
              v-model="pagamento.HasInterestInstallment"
            />
          </div>
        </div>

        <template v-if="pagamento.HasInterestInstallment">
          <ValidationProvider
            name="cobrar juros nas parcelas?"
            :rules="{
              required: true,
              max: pagamento.MaxNumberInstallment
            }"
            v-slot="{ errors }"
            class="vx-col lg:w-1/3 md:w-1/2 w-full"
          >
            <vs-select
              class="large w-full"
              label="Número máximo de parcelas sem juros"
              v-model="pagamento.MaxNumberInstallmentWithoutInterest"
              :danger-text="errors[0]"
              :danger="!!errors[0]"
            >
              <vs-select-item
                :value="item"
                :text="item"
                :key="item"
                v-for="item in 7"
              />
            </vs-select>
          </ValidationProvider>

          <ValidationProvider
            name="(%) juros"
            rules="required"
            v-slot="{ errors }"
            class="vx-col lg:w-1/3 md:w-1/2 w-full"
          >
            <vs-input
              label="(%) juros"
              class="w-full"
              v-model.number="pagamento.InterestPercentage"
              size="large"
              :danger-text="errors[0]"
              :danger="!!errors[0]"
              v-mask="'#####'"
            />
          </ValidationProvider>
        </template>
      </vs-row>

      <vs-row
        vs-type="flex"
        vs-justify="flex-end"
        class="mb-5"
        v-if="!!tabPagamentoParameter && tabPagamentoParameter.UniqueId"
      >
        <vs-col
          vs-type="flex"
          vs-align="center"
          vs-sm="12"
          vs-justify="flex-end"
        >
          <vs-button
            @click="openModalConfig()"
            class="button-primary block pr-3"
          >
            Gerenciar relacionamento do parâmetro com grupo/modalidade(s)
          </vs-button>
        </vs-col>
      </vs-row>

      <vs-row vs-type="flex" vs-justify="flex-end">
        <vs-col
          vs-type="flex"
          vs-justify="end"
          vs-align="center"
          vs-lg="2"
          vs-sm="12"
        >
          <vs-button
            class="button-primary"
            @click="salvarPagamentoByClass()"
            :disabled="invalid"
          >
            Salvar
          </vs-button>
        </vs-col>
      </vs-row>
    </ValidationObserver>

    <vs-popup
      class="relacinamento-modal"
      title="Gerenciar relacionamento do parâmetro com grupo/modalidade(s)"
      :active.sync="showConfigModalityModal"
      v-if="!!tabPagamentoParameter && tabPagamentoParameter.UniqueId"
    >
      <ValidationObserver v-slot="{ invalid }" class="mt-10" tag="div">
        <vs-row v-for="(grupo, index) of modalityGroupPagamento" :key="index">
          <ValidationProvider
            name="grupo de modalidade"
            rules="required"
            v-slot="{ errors }"
            class="vx-col md:w-1/4 w-full"
          >
            <vs-select
              class="large w-full"
              label="Grupo"
              v-model="grupo.ModalityGroupUniqueId"
              :danger-text="errors[0]"
              :danger="!!errors[0]"
              @change="
                loadModalities(grupo.ModalityGroupUniqueId, index);
                grupo.Modalities = [];
              "
            >
              <vs-select-item
                :value="item.ModalityGroupUniqueId"
                :text="item.ModalityGroupName"
                :key="indexGroup"
                v-for="(item, indexGroup) of modalitiesGroups"
              />
            </vs-select>
          </ValidationProvider>

          <div class="vx-col md:w-2/4 w-full container-element">
            <label class="label-content label">Modalidades</label>
            <multiselect
              :disabled="
                !grupo.ModalityGroupUniqueId ||
                  !getModalities(grupo.ModalityGroupUniqueId, index)
              "
              :searchable="false"
              :internal-search="false"
              :clear-on-select="true"
              :max-height="200"
              :options="getModalities(grupo.ModalityGroupUniqueId, index)"
              v-model="grupo.Modalities"
              open-direction="bottom"
              placeholder="Selecione"
              taggable
              multiple
              groupSelect
              trackBy="ModalityName"
              label="ModalityName"
              :showLabels="false"
              @input="validModalidadePacelamentoDiferenciado(index)"
              @blur="validModalidadePacelamentoDiferenciado(index)"
            >
              <template
                slot="selection"
                slot-scope="{ values }"
                v-if="values && values.length"
              >
                <vs-chip
                  closable
                  @click="removeModality(index, 0)"
                  transparent
                  border
                >
                  {{ values[0].ModalityName }}
                </vs-chip>

                <vs-chip
                  class="float-right"
                  v-if="values.length > 1"
                  color="primary"
                >
                  <vs-avatar icon="add" />
                  {{ values.length - 1 }}
                </vs-chip>
              </template>

              <template slot="option" slot-scope="props" color="primary"
                >{{ props.option.ModalityName }}
              </template>
            </multiselect>

            <span class="text-danger text-sm" v-show="hasError[index]">
              Selecione ao menos 1 modalidade
            </span>
          </div>

          <ValidationProvider
            name="grupo de modalidade"
            rules="required"
            v-slot="{ errors }"
            class="vx-col md:w-2/12 w-full"
          >
            <vs-select
              class="large w-full"
              label="Número máximo de parcelas"
              v-model="grupo.MaxNumberInstallment"
              :danger-text="errors[0]"
              :danger="!!errors[0]"
            >
              <vs-select-item
                :value="item"
                :text="item"
                :key="item"
                v-for="item in 7"
              />
            </vs-select>
          </ValidationProvider>

          <div class="vx-col md:w-1/12 w-full">
            <i
              class="onpoint-trash text-primary"
              role="button"
              @click="removePagamentoDiferenciado(index)"
            ></i>
          </div>
        </vs-row>

        <vs-row vs-type="flex" vs-justify="end" class="mb-5">
          <vs-col vs-type="flex" vs-justify="end" vs-align="center" vs-col="12">
            <vs-button
              @click="addParcelamentoDiferenciado"
              color="primary"
              class="mt-3"
              type="flat"
            >
              <i class="onpoint-plus pr-2"></i>
              <b class="">Adicionar parcelamento diferenciado</b>
            </vs-button>
          </vs-col>
        </vs-row>

        <vs-row vs-type="flex" vs-justify="end">
          <vs-col
            vs-type="flex"
            vs-justify="end"
            vs-align="center"
            vs-lg="3"
            vs-sm="12"
          >
            <vs-button
              @click="showConfigModalityModal = false"
              class="button-default mr-4"
            >
              Cancelar
            </vs-button>

            <vs-button
              @click="salvarParcelamentoDiferenciado()"
              class="button-primary"
              :disabled="invalid || hasParcelamendoDiferenciadoInvalido"
            >
              Salvar
            </vs-button>
          </vs-col>
        </vs-row>
      </ValidationObserver>
    </vs-popup>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect
  },
  props: {
    tagClass: { type: String }
  },
  directives: { mask },
  data() {
    return {
      pagamento: {},
      hasError: [],
      modalityGroupPagamento: [],
      showConfigModalityModal: false
    };
  },
  created() {
    this.pagamento = this.$utils.deepClone(this.tabPagamentoParameter);
  },
  watch: {
    tabPagamentoParameter(value) {
      this.pagamento = this.$utils.deepClone(value);
    }
  },
  computed: {
    ...mapGetters("parametrizacao-module", [
      "tabPagamentoParameter",
      "modalitiesGroups"
    ]),

    hasParcelamendoDiferenciadoInvalido() {
      let invalid = false;
      this.modalityGroupPagamento.forEach(element => {
        if (!element.Modalities || !element.Modalities.length) invalid = true;
      });
      return invalid;
    },

    paymentFrequency() {
      return [
        { value: 7, text: "Semanal" },
        { value: 15, text: "Quinzenal" },
        { value: 30, text: "Mensal" },
        { value: 90, text: "Trimestral" },
        { value: 180, text: "Semestral" }
      ];
    }
  },
  methods: {
    ...mapMutations("parametrizacao-module", ["updateTabsPagamento"]),
    ...mapActions("parametrizacao-module", [
      "getModalitiesByGroup",
      "createInstallmentParameter",
      "changeInstallmentParameter",
      "changeInstallmentModalityParameter",
      "deleteInstallmentModalityParameter"
    ]),

    loadModalities(uuid, index) {
      this.getModalitiesByGroup(uuid)
        .catch(ex => {
          this.$onpoint.errorModal(ex.response.data.Errors);
        })
        .finally(() => {
          this.hasError[index] = false;
          this.$forceUpdate();
        });
      this.$forceUpdate();
    },

    openModalConfig() {
      this.showConfigModalityModal = true;
      this.modalityGroupPagamento = this.$utils.deepClone(
        this.pagamento.ModalityGroup
      );
    },

    removeModality(group, modality) {
      this.modalityGroupPagamento[group].Modalities.splice(modality, 1);
      this.validModalidadePacelamentoDiferenciado(group);
    },

    validModalidadePacelamentoDiferenciado(group) {
      if (
        this.modalityGroupPagamento[group] &&
        (!this.modalityGroupPagamento[group].Modalities ||
          !this.modalityGroupPagamento[group].Modalities.length)
      )
        this.hasError[group] = true;
      else this.hasError[group] = false;
    },

    addParcelamentoDiferenciado() {
      this.modalityGroupPagamento.push({ Modalities: [] });
    },

    getModalities(groupUniqueId, index) {
      if (!this.modalitiesGroups || !this.modalitiesGroups.length) return null;

      let item =
        this.modalitiesGroups.filter(grupo => {
          return grupo.ModalityGroupUniqueId == groupUniqueId;
        })[0] || {};

      let modalities = this.$utils.deepClone(item.Modalities || []);

      for (var i in this.modalityGroupPagamento) {
        if (i != index)
          if (
            this.modalityGroupPagamento[i].ModalityGroupUniqueId ==
            groupUniqueId
          )
            this.modalityGroupPagamento[i].Modalities.forEach(modality => {
              modalities = modalities.filter(
                x => x.ModalityUniqueId != modality.ModalityUniqueId
              );
            });
      }
      return modalities;
    },

    removePagamentoDiferenciado(group) {
      if (((this.pagamento.ModalityGroup[group] || {}).Modalities || []).length)
        this.$onpoint.loading(() => {
          let payload = {
            data: {
              InstallmentParameterUniqueId: this.pagamento.UniqueId,
              ModalitiesUniqueIds: this.pagamento.ModalityGroup[
                group
              ].Modalities.map(function(item) {
                return item.ModalityUniqueId;
              })
            }
          };

          return this.deleteInstallmentModalityParameter(payload).then(
            response => {
              this.modalityGroupPagamento.splice(group, 1);
              this.pagamento.ModalityGroup.splice(group, 1);
              this.updateTabsPagamento(this.pagamento);
              this.validModalidadePacelamentoDiferenciagdo(group);
            }
          );
        });
      else this.modalityGroupPagamento.splice(group, 1);
    },

    salvarParcelamentoDiferenciado() {
      if (this.hasError.filter(error => !!error).length) return;

      let payload = {
        InstallmentParameterUniqueId: this.pagamento.UniqueId,
        Modalities: []
      };

      this.modalityGroupPagamento.forEach(group => {
        payload.Modalities.push({
          ModalitiesUniqueIds: group.Modalities.map(function(item) {
            return item.ModalityUniqueId;
          }),
          MaxNumberInstallment: group.MaxNumberInstallment
        });
      });

      this.$onpoint.loading(() => {
        return this.changeInstallmentModalityParameter(payload)
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          })
          .then(() => {
            this.pagamento.ModalityGroup = this.$utils.deepClone(
              this.modalityGroupPagamento
            );
            this.updateTabsPagamento(this.pagamento);

            this.showConfigModalityModal = false;
          });
      });
    },

    salvarPagamentoByClass() {
      let payload = {
        uniqueId: this.pagamento.UniqueId,
        class: this.pagamento.Class || this.tagClass,
        name: this.pagamento.Name,
        maxNumberInstallment: this.pagamento.MaxNumberInstallment,
        minValueInstallment: this.pagamento.MinValueInstallment,
        minDaysFirstInstallment: this.pagamento.MinDaysFirstInstallment,
        maxDaysFirstInstallment: this.pagamento.MaxDaysFirstInstallment,
        paymentFrequency: this.pagamento.PaymentFrequency,
        dueDateLastInstallment: this.pagamento.DueDateLastInstallment,
        hasInterestInstallment: this.pagamento.HasInterestInstallment,
        maxNumberInstallmentWithoutInterest: this.pagamento
          .HasInterestInstallment
          ? this.pagamento.MaxNumberInstallmentWithoutInterest
          : null,
        interestPercentage: this.pagamento.HasInterestInstallment
          ? this.pagamento.InterestPercentage
          : null
      };

      this.$onpoint.loading(() => {
        if (!this.pagamento.UniqueId) {
          return this.createInstallmentParameter(payload)
            .catch(ex => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            })
            .then(response => {
              this.pagamento.UniqueId = response;
              this.updateTabsPagamento(this.pagamento);
            });
        } else {
          return this.changeInstallmentParameter(payload)
            .catch(ex => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            })
            .then(() => {
              this.updateTabsPagamento(this.pagamento);
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../../assets/scss/mixins";

.multiselect__content-wrapper {
  width: 91%;

  margin: 0 4%;
}
.multiselect__option {
  background: white;
  color: rgba(var(--vs-primary), 1);
  border-bottom: 1px solid #e8e8e8;
}

.multiselect__option--highlight {
  background: #f1f1f1;
}

.multiselect__option--highlight:after {
  display: none;
}

.multiselect__tags {
  border-radius: 50px !important;
  height: 49.2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 10px;
}

.multiselect__tag {
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 40%;
}

.multiselect__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  padding-left: 6px !important;
  padding-top: 0 !important;
}

.multiselect__input {
  font-size: 13px !important;

  @include lg {
    font-size: 16px !important;
  }
}

.container-element {
  position: relative;

  .label-content {
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.6);
    padding-left: 5px;
  }

  .label {
    transform: translateY(-95%) scale(0.9);
    transform-origin: top-left;
    position: absolute;
  }
}

.multiselect--disabled .multiselect__select,
.multiselect--disabled {
  background: transparent;
}
</style>

<style lang="scss" scoped>
.vx-col {
  padding: 15px 15px;
}

.switch-container {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;

  label {
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>

<style lang="scss">
.parent-dropdown {
  min-height: 80px;

  .vs-input,
  .con-select {
    min-height: 47px !important;
    transform: translateY(-36%) !important;
  }
}

.active .vs-dropdown--item-link {
  background: rgba(var(--vs-primary), 0.09) !important;
}

.vs-input,
.con-select {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
  min-height: 80px;
}

.vs-con-input-label .vs-input--label,
.con-select .vs-select--label {
  transform: translateY(-95%) scale(0.9);
  transform-origin: top left !important;
  pointer-events: auto;
  position: absolute;
  font-weight: 400;
  text-align: initial;
  font-family: #{var(--vs-font-family)};
}

.input-icon-validate-danger {
  label {
    color: rgba(var(--vs-danger), 1) !important;
  }
}

.vs-con-input {
  .input-icon-validate {
    display: none;
  }
}

.con-vs-popup .vs-popup {
  width: 1000px;
  max-width: 100%;
}
</style>
